import React, { FC } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';

import ProgressBar from '@components/ProgressBar';
import Header from '@components/Header';
import Headline from '@components/Headline';
import Quote from '@components/Quote';
import OurTools from '@components/OurTools';
import UsedBy from '@components/UsedBy';
import AboutProjects from '@components/AboutProjects';
import Supporters from '@components/Supporters';
import SeoGenerator from '@components/SeoGenerator';

import ArticleFooter from '@components/ArticleFooter';
import { TRouter } from '@local-types/global';
import landingPage from '@data/landingPage';

import styles from './LandingLayout.module.scss';

type HomeProps = {
  headline: string;
  linkedInUrl: string;
  xUrl: string;
  quote: string;
  tools: any;
  usedBy: any;
  supporters: any;
  projects: any;
  pageSeo: {
    seoDescription: string;
    seoTitle: string;
    keywords: string;
    pageTitle: string;
  };
};

type LandingLayoutProps = {
  withDisclimer?: boolean;
  articles?: any;
  currentUrl: string;
  children?: any;
  homeData?: HomeProps;
  darkTheme?: boolean;
};

const LandingLayout: FC<LandingLayoutProps> = ({
  withDisclimer,
  articles,
  currentUrl,
  homeData,
  darkTheme,
}) => {
  const {
    seoDescription: description,
    seoTitle: title,
    keywords,
    pageTitle,
  } = homeData.pageSeo;
  const router = useRouter();
  const { locale } = router as TRouter;
  const { ourTools, supportedBy, usedBy } = landingPage[locale];
  return (
    <>
      <SeoGenerator strapiSEO={{ description, title, keywords, pageTitle }} />
      <ProgressBar />
      <Header
        withDisclimer={!!withDisclimer}
        articles={articles}
        activeUrl={currentUrl}
        isCompanyManagementPage={true}
      />
      <Headline
        headline={homeData?.headline}
        linkedInUrl={homeData?.linkedInUrl}
        xUrl={homeData?.xUrl}
        darkTheme={darkTheme}
        russianView={locale === 'ru'}
      />
      <div
        className={cn(styles.mainSections, {
          [styles.darkTheme]: darkTheme,
          [styles.russianView]: locale === 'ru',
        })}
      >
        <Quote
          quote={homeData?.quote}
          darkTheme={darkTheme}
          russianView={locale === 'ru'}
        />
        <OurTools
          tools={homeData?.tools}
          darkTheme={darkTheme}
          title={ourTools}
          russianView={locale === 'ru'}
        />
        <UsedBy
          usedBy={homeData?.usedBy}
          darkTheme={darkTheme}
          title={usedBy}
        />
        <Supporters
          supporters={homeData?.supporters}
          darkTheme={darkTheme}
          title={supportedBy}
        />
        <AboutProjects projects={homeData?.projects} darkTheme={darkTheme} />
      </div>
      <ArticleFooter />
    </>
  );
};
export default LandingLayout;
