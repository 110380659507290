const UXCoreGray = () => (
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.35">
      <path
        d="M35.0448 13.125V8.75C28.025 8.75 21.4253 11.48 16.4618 16.4369C11.4962 21.3938 8.76172 27.987 8.76172 34.998C8.76172 42.009 11.4962 48.6 16.4618 53.5613C21.4275 58.516 28.0272 61.246 35.0448 61.246V56.871C29.1932 56.871 23.6916 54.596 19.5528 50.4638C15.4162 46.3293 13.1368 40.8365 13.1368 34.9958C13.1368 29.1529 15.4162 23.6623 19.5528 19.5301C23.6894 15.4 29.191 13.125 35.0448 13.125Z"
        fill="#617181"
      />
      <path
        d="M35.0506 45.9348C41.0997 45.9348 46.0035 41.0379 46.0035 34.9972C46.0035 28.9566 41.0997 24.0596 35.0506 24.0596C29.0015 24.0596 24.0977 28.9566 24.0977 34.9972C24.0977 41.0379 29.0015 45.9348 35.0506 45.9348Z"
        fill="#617181"
      />
      <path
        d="M65.6381 37.2184C65.205 43.1794 63.0525 48.8407 59.419 53.592C55.7833 58.3433 50.8767 61.9046 45.2264 63.8864C39.5826 65.8705 33.5253 66.1658 27.7065 64.7352C21.8921 63.3046 16.6639 60.2333 12.5864 55.8539C8.50883 51.4767 5.82256 46.0494 4.81411 40.1562C3.80567 34.2653 4.5363 28.2539 6.92726 22.772C9.31821 17.2901 13.2295 12.6613 18.2368 9.38664C23.2484 6.10755 29.0606 4.37503 35.0479 4.37503V0C28.2075 0 21.5662 1.9797 15.8436 5.72254C10.1211 9.46539 5.65193 14.7548 2.91972 21.0198C0.185325 27.2849 -0.648121 34.1559 0.502514 40.8912C1.65315 47.6266 4.72661 53.8304 9.38603 58.8333C14.0455 63.8361 20.0196 67.3449 26.6631 68.979C29.4303 69.6615 32.2456 70.0006 35.0566 70.0006C38.992 70.0006 42.9164 69.3334 46.6789 68.0121C53.1321 65.748 58.7387 61.6792 62.8928 56.2476C67.0469 50.8204 69.5057 44.3497 70 37.5334L65.6381 37.2184Z"
        fill="#617181"
      />
      <path
        d="M69.7556 30.1505C68.745 22.9229 65.56 16.2947 60.5418 10.9834L57.3633 13.9869C61.7515 18.6332 64.5384 24.4323 65.4243 30.7564L69.7556 30.1505Z"
        fill="#617181"
      />
      <path
        d="M57.2424 7.91674C56.0174 6.91704 54.7202 5.9961 53.3837 5.17578L51.0977 8.9055C52.2658 9.623 53.4011 10.4302 54.473 11.3052L57.2424 7.91674Z"
        fill="#617181"
      />
    </g>
  </svg>
);

export default UXCoreGray;
