import { FC } from 'react';
import cn from 'classnames';

import styles from './AboutProjects.module.scss';

type projectsProps = {
  id: number;
  project_name: string;
  description: string;
};
type AboutProjectsProps = {
  projects: projectsProps[];
  darkTheme?: boolean;
};

const AboutProjects: FC<AboutProjectsProps> = ({ projects, darkTheme }) => {
  return (
    <section
      className={cn(styles.projects, {
        [styles.darkTheme]: darkTheme,
      })}
    >
      {projects.map((project: any) => (
        <div key={project.id} className={styles.project}>
          <hr className={styles.redLine} />
          <h3 className={styles.name}>{project.project_name}</h3>
          <div dangerouslySetInnerHTML={{ __html: project.description }} />
        </div>
      ))}
    </section>
  );
};

export default AboutProjects;
