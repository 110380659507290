import { FC } from 'react';
import ToolContainer from '@components/ToolContainer';
import cn from 'classnames';

import UXCoreGray from '@icons/UXCoreGray';
import PyramidsGray from '@icons/PyramidsGray';
import BobGray from '@icons/BobGray';
import BobLight from '@icons/BobLight';

import styles from './OurTools.module.scss';

type Tool = {
  name: string;
  description: string;
  slug: string;
};

type OurToolsProps = {
  tools: Tool[];
  darkTheme?: boolean;
  title?: string;
  russianView?: boolean;
};

const OurTools: FC<OurToolsProps> = ({
  tools,
  darkTheme,
  title,
  russianView,
}) => {
  const bobIcon = darkTheme ? <BobGray /> : <BobLight />;
  const japaneseNames = {
    uxcore: '主要',
    'company-management': '必要のピラミッド',
    bob: 'アシスタント',
  };
  const urls = {
    uxcore: '/uxcore',
    'company-management': '/company-management',
    bob: 'https://chat.openai.com/g/g-BtuSiGF18-bob-trickery-and-deception-by-ux-core',
  };

  const icons = {
    uxcore: <UXCoreGray />,
    'company-management': <PyramidsGray />,
    bob: bobIcon,
  };
  const sizes = {
    uxcore: 'small',
    'company-management': 'medium',
    bob: 'large',
  };

  const toolItems = tools.map(tool => {
    return {
      ...tool,
      japaneseName: japaneseNames[tool.slug],
      url: urls[tool.slug],
      icon: icons[tool.slug],
      size: sizes[tool.slug],
    };
  });

  return (
    <section
      className={cn(styles.tools, {
        [styles.darkTheme]: darkTheme,
      })}
    >
      <h2 className={styles.title}> {title} </h2>
      <div className={styles.toolsWrapper}>
        {toolItems.map((tool, index) => (
          <ToolContainer
            darkTheme={darkTheme}
            size={tool.size}
            icon={tool.icon}
            url={tool.url}
            japaneseName={tool.japaneseName}
            name={tool.name}
            description={tool.description}
            key={index}
            russianView={russianView}
            isBobUrl={tool.slug === 'bob'}
          />
        ))}
      </div>
    </section>
  );
};

export default OurTools;
