import React, { FC } from 'react';
import cn from 'classnames';

import useGlobals from '@hooks/useGlobals';
import LandingLayout from '@layouts/LandingLayout';

import ProgressBar from '@components/ProgressBar';
import ZoomBlock from '@components/ZoomBlock';
import PinBlock from '@components/PinBlock';
import Header from '@components/Header';
import ArticleFooter from '@components/ArticleFooter';

import styles from './HomeLayout.module.scss';

type THomeLayout = {
  withDisclimer?: boolean;
  articles?: any;
  currentUrl: string;
  children?: any;
  homeData?: any;
};

const HomeLayout: FC<THomeLayout> = ({
  withDisclimer,
  homeData,
  children,
  articles,
  currentUrl,
}) => {
  const [{}, { isFullScreen, isDarkTheme }] = useGlobals();

  return currentUrl === '/' ? (
    <LandingLayout
      currentUrl={currentUrl}
      articles={articles}
      homeData={homeData}
      withDisclimer={!!withDisclimer}
      darkTheme={isDarkTheme}
    />
  ) : (
    <div
      className={cn({
        [styles.darkBg]: isDarkTheme && currentUrl === '/',
        [styles.pageWrapper]: currentUrl === '/',
      })}
    >
      <ProgressBar />
      <Header
        withDisclimer={!!withDisclimer}
        articles={articles}
        activeUrl={currentUrl}
        isCompanyManagementPage={true}
      />
      <main className={styles.main}>
        <article
          className={cn(styles.article, {
            [styles.fullscreen]: isFullScreen,
            [styles.darkTheme]: isDarkTheme,
          })}
        >
          <section className={styles.section}>{children}</section>
        </article>
      </main>
      <ArticleFooter />
      <PinBlock />
      <ZoomBlock />
    </div>
  );
};

export default HomeLayout;
