import { FC } from 'react';
import styles from './ToolContainer.module.scss';
import cn from 'classnames';

type ToolContainerProps = {
  size: 'small' | 'medium' | 'large';
  icon: any;
  japaneseName: string;
  url: string;
  description: string;
  name: string;
  darkTheme?: boolean;
  russianView?: boolean;
  isBobUrl?: boolean;
};
const ToolContainer: FC<ToolContainerProps> = ({
  size,
  icon,
  description,
  japaneseName,
  name,
  darkTheme,
  url,
  russianView,
  isBobUrl,
}) => {
  return (
    <a
      href={url}
      className={styles.tool}
      target={isBobUrl ? '_blank' : '_self'}
    >
      <div
        className={cn(styles.wrapper, {
          [styles.small]: size === 'small',
          [styles.medium]: size === 'medium',
          [styles.large]: size === 'large',
          [styles.darkTheme]: darkTheme,
          [styles.russianView]: russianView,
        })}
      >
        <div className={styles.mainContent}>
          <div className={styles.iconWrapper}>
            {icon}
            <h3 className={styles.japaneseName}> {japaneseName}</h3>{' '}
          </div>
          <h4 className={styles.name}>{name}</h4>
          <div
            className={styles.quote}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </a>
  );
};

export default ToolContainer;
