import { FC } from 'react';
import cn from 'classnames';

import styles from './Quote.module.scss';

type QuoteProps = {
  quote: string;
  darkTheme?: boolean;
  russianView?: boolean;
};
const Quote: FC<QuoteProps> = ({ quote, darkTheme, russianView }) => {
  return (
    <section
      className={cn(styles.quoteSection, {
        [styles.darkTheme]: darkTheme,
        [styles.russianView]: russianView,
      })}
    >
      <div
        className={styles.quote}
        dangerouslySetInnerHTML={{ __html: quote }}
      />
    </section>
  );
};

export default Quote;
