const PyramidsGray = () => (
  <svg
    width="65"
    height="60"
    viewBox="0 0 65 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.35"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.98 0L58.434 46.3394L25.476 46.2466L30.189 38.5993L41.528 38.6921L27.063 10.9563L32.98 0ZM65 60H0L24.749 15.3254L31.007 27.3149L17.34 50.5812H59.815L65 60Z"
      fill="#617181"
    />
  </svg>
);

export default PyramidsGray;
