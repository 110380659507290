import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { GetStaticPaths, GetStaticProps } from 'next';

import ContentHandler from '@components/ContentHandler';
import SeoGenerator from '@components/SeoGenerator';
import Spinner from '@components/Spinner';
import Custom404 from './404';

import HomeLayout from '@layouts/HomeLayout';

import { getArticlesPaths } from '@lib/paths';

import {
  getArticles,
  getCategories,
  getCurrentArticle,
  getHomeData,
  getNavData,
  getPaths,
  modifyCategories,
} from '@api/strapi';

import {
  TArticle,
  TCategory,
  THomePage,
  TLocales,
  TPaths,
  TStaticProps,
} from '@local-types/data';

import pageNotFoundData from '@data/404';

import useGlobals from '@hooks/useGlobals';

type ArticleProps = {
  data: TArticle | THomePage;
  isCompanyManagementPage: boolean;
  articles?: TCategory[];
  locale: TLocales;
  homeData?: any;
};

const Article = ({ data, articles, locale, homeData }: ArticleProps) => {
  const {
    seoDescription: description,
    seoTitle: title,
    keywords,
    pageTitle,
  } = data;

  const router = useRouter();
  const currentUrl = router.asPath;
  const articleRef = useRef<HTMLElement>(null);

  const [link, setLink] = useState(currentUrl);
  const [{ initUseGlobals, unmountUseGlobals }] = useGlobals();

  useEffect(() => {
    const hash = currentUrl.split('?' || '#')[1];

    if (hash) {
      setLink('/');
    } else {
      setLink(currentUrl);
    }
  }, [currentUrl, link]);

  if (!Object.keys(data).length) {
    return <Custom404 intl={pageNotFoundData[locale]} locale={locale} />;
  }
  const about = homeData?.about;
  const projectContent = homeData?.project_content;

  useEffect(() => {
    initUseGlobals(articleRef.current);

    return () => {
      unmountUseGlobals();
    };
  }, []);

  useEffect(() => {
    return () => {
      document.body.classList.remove('darkTheme');
    };
  }, []);

  return (
    <section ref={articleRef}>
      <SeoGenerator strapiSEO={{ description, title, keywords, pageTitle }} />
      <HomeLayout articles={articles} currentUrl={link} homeData={homeData}>
        <ContentHandler
          data={data}
          about={about}
          projectContent={projectContent}
        />
      </HomeLayout>
      <Spinner />
    </section>
  );
};

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  const paths = getArticlesPaths(locales);
  const strapiPaths: TPaths[] = await getPaths();

  return {
    paths: [...strapiPaths, ...paths],
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps = async ({
  params: { page },
  locale,
}: TStaticProps) => {
  const articles: TArticle[] = await getArticles(locale);
  const categories: TCategory[] = await getCategories(locale);
  const homeData = await getHomeData(locale);
  const filteredArticles = articles.filter(
    article => article.attributes.url !== 'company-management',
  );
  modifyCategories(filteredArticles, categories);
  const navData = getNavData(categories, locale);
  const article = page
    ? getCurrentArticle(filteredArticles, page[0])
    : homeData;

  return {
    props: {
      articles: navData,
      data: article,
      locale,
      homeData,
    },
    revalidate: 5,
  };
};

export default Article;
