import { FC } from 'react';
import cn from 'classnames';

import SupporterContainer from '@components/SupporterContainer';

import styles from './Supporters.module.scss';

type Supporter = {
  id: number;
  name: string;
  quote: string;
  about: string;

  image: {
    data: {
      attributes: {
        url: string;
      };
    };
  };
};

type SupportersProps = {
  supporters: Supporter[];
  darkTheme?: boolean;
  title?: string;
};

const Supporters: FC<SupportersProps> = ({ supporters, darkTheme, title }) => {
  return (
    <section
      className={cn(styles.supporters, {
        [styles.darkTheme]: darkTheme,
      })}
    >
      <h2 className={styles.title}> {title} </h2>
      <div className={styles.wrapper}>
        {supporters.map((supporter: any) => (
          <SupporterContainer
            darkTheme={darkTheme}
            key={supporter.id}
            quote={supporter.quote}
            about={supporter?.about}
            name={supporter.name}
            image={supporter.image.data.attributes.url}
          />
        ))}
      </div>
    </section>
  );
};

export default Supporters;
