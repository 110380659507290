import type { FC } from 'react';
import { Fragment } from 'react';
import cn from 'classnames';

import useGlobals from '@hooks/useGlobals';

import ContentParser from '@components/ContentParser';

import styles from './ContentHandler.module.scss';
import { useRouter } from 'next/router';
import type { TRouter } from '@local-types/global';

type TContentHandler = {
  data: any;
  projectContent?: any;
  about?: any;
};

const ContentHandler: FC<TContentHandler> = ({ data }) => {
  const { isDarkTheme } = useGlobals()[1];
  const router = useRouter();
  const { locale } = router as TRouter;
  const currentUrl = router.asPath;
  const aboutTxt = locale === 'en' ? 'About' : 'О проекте';
  const projectContentTxt =
    locale === 'en' ? 'Project Content' : 'Контент проекта';

  return (
    <Fragment>
      <div
        className={cn(styles.contentHandlerContainer, {
          [styles.darkTheme]: isDarkTheme,
          [styles.ruVersion]: locale === "ru"
        })}
      >
        {currentUrl === '/' ? (
          <Fragment>
            <div className={styles.homePageWrapper}>
              <h2
                className={cn(styles.title, {
                  [styles.ruTitle]: locale === 'ru',
                })}
              >
                {aboutTxt}
              </h2>
              <div className={styles.homeContent}>
                <ContentParser
                  data={data.about}
                  styles={styles}
                  usePTag={false}
                />
              </div>
            </div>
            <div className={styles.homePageWrapper}>
              {/*TODO MARY - Add also russian*/}
              <h2
                className={cn(styles.title, {
                  [styles.ruTitle]: locale === 'ru',
                })}
              >
                {projectContentTxt}{' '}
              </h2>
              <div className={styles.homeContent}>
                <ContentParser
                  data={data.project_content}
                  styles={styles}
                  usePTag={false}
                />
              </div>
            </div>
          </Fragment>
        ) : (
          <ContentParser data={data.content} styles={styles} usePTag={false} />
        )}
      </div>
    </Fragment>
  );
};

export default ContentHandler;
